<template>
  <b-card>
    <b-modal
        hide-footer
        id="modal-prevent-move-line-erp"
        ref="my-modal"
        :title="`Producto caducado`"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        size="lg"
    >
      <b-row>
        <b-overlay :show="show2" no-wrap/>
        <b-col cols="12" style="margin-bottom: 40px">
          <h3>
            Actualmente este movimiento no puede validarse. La fecha de caducidad del producto: <strong>{{
              productError
            }} </strong> ya paso. Consulte con su administrador
          </h3>

        </b-col>
        <b-col cols="12">
          <b-button
              @click="openOtherErp()"
              style="width: 100%; margin-bottom: 40px"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="primary"
              class="mr-1"
          >
            Ir al admin
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
        hide-footer
        id="modal-prevent-move-line"
        ref="my-modal"
        :title="`Producto caducado`"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @show="resetModalQr"
        @hidden="resetModalQr"
        size="lg"
    >

      <b-row>
        <b-overlay :show="show2" no-wrap/>
        <b-col cols="12" style="margin-bottom: 40px">
          <h2>{{ dataError }}</h2>

        </b-col>
        <b-col cols="6">
          <b-button
              @click="createLinesMove()"
              style="width: 100%; margin-bottom: 40px"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="primary"
              class="mr-1"
          >
            Guardar
          </b-button>
        </b-col>
        <b-col cols="6">
          <b-button
              @click="cancelModal()"
              style="width: 100%; margin-bottom: 40px"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="primary"
              class="mr-1"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <div class="m-2">
      <b-overlay :show="show" no-wrap/>
      <b-row>
        <b-col lg="12">
          <b-form @submit.prevent="sendInventory(send)">
            <b-row>
              <b-col cols="12" style="margin-bottom: 30px">
                <h3>Rerencia: <strong>{{ reference }}</strong></h3><br>
                <h3>Estatus: <strong>{{ changeTextSpanishSingleInventory(stateAll) }}</strong></h3>

              </b-col>
              <b-col cols="6">
                <b-form-group label="Fecha de transaccion">
                  <flat-pickr
                      v-model="scheduled_date"
                      class="form-control"
                      disabled=""
                      :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i:m',
                      locale: {
                        firstDayOfWeek: 1,
                        weekdays: {
                          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                          longhand: [
                            'Domingo',
                            'Lunes',
                            'Martes',
                            'Miércoles',
                            'Jueves',
                            'Viernes',
                            'Sábado',
                          ],
                        },
                        months: {
                          shorthand: [
                            'Ene',
                            'Feb',
                            'Mar',
                            'Abr',
                            'May',
                            'Jun',
                            'Jul',
                            'Ago',
                            'Sep',
                            'Оct',
                            'Nov',
                            'Dic',
                          ],
                          longhand: [
                            'Enero',
                            'Febrero',
                            'Мarzo',
                            'Abril',
                            'Mayo',
                            'Junio',
                            'Julio',
                            'Agosto',
                            'Septiembre',
                            'Octubre',
                            'Noviembre',
                            'Diciembre',
                          ],
                        },
                      },
                    }"
                  />

                </b-form-group>
                <b-form-group label="Paciente">
                  <v-select
                      style="border-radius: 1px !important"
                      v-model="selectPartner"
                      disabled=""
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="value"
                      @input="searchByPartner()"
                      :options="optionsPartners"

                  />
                </b-form-group>
                <b-form-group label="Ubicación">
                  <v-select
                      style="border-radius: 1px !important"
                      v-model="selectLocation"
                      disabled="disabled"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="value"
                      :options="optionsLocations"
                  />
                </b-form-group>
                <b-form-group label="Documento de origen">
                  <b-form-input
                      disabled=""
                      v-model="send.origin"
                      placeholder="Documento de origen"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="6" class="mt-1">
                <b-row>
                  <b-col cols="12" lg="6">
                    <div align="left" v-if="stateAll === 'draft'">
                      <b-button
                          style="width: 100%; margin-top: 10px"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="submit"
                          variant="primary"
                          class="mr-1"
                      >
                        Guardar
                      </b-button>
                      <b-button
                          v-if="!linesMove"
                          @click="validateSuccess()"
                          style="width: 100%; margin-top: 10px"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Procesar
                      </b-button>
                    </div>
                    <div align="left" v-if="stateAll === 'assigned'">
                      <b-button
                          @click="validateDataStock()"
                          style="width: 100%; margin-top: 10px"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Validar Salida
                      </b-button>
                    </div>
                    <div align="left" v-if="stateAll === 'done'">
                      <h3 style="margin-top: 10px">
                        Salida validada correctamente
                      </h3>
                    </div>
                  </b-col>

                  <b-col cols="12" lg="6">
                    <div align="right">
                      <b-button
                          @click="cancelUpdate()"
                          style="width: 100%; margin-top: 10px"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="primary"
                          class="mr-1"
                      >
                        Cancelar
                      </b-button>

                    </div>

                  </b-col>
                </b-row>

              </b-col>
              <b-col cols="12" class="mt-3">
                <b-tabs style="margin-top: 30px">
                  <b-tab active title="Operaciones detalladas">
                    <b-col cols="12" class="mt-3">
                      <table>
                        <tr>
                          <th>Producto</th>
                          <th>Unidad de medida</th>
                          <th>Lote</th>
                          <th>Cantidad Disponible</th>
                          <th>Cantidad Hecha</th>
                          <th>Acciones</th>
                        </tr>
                        <tr v-for="(p, idx) in new_MoveLines" :key="idx">

                          <td>
                            <v-select
                                style="width: 350px"
                                v-model="p.productData"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="value"
                                :options="product"
                                :clearable="false"
                                @input="fetchLotes(p.productData,idx)"
                                class="no-clear-icon"
                            />
                          </td>
                          <td>
                            <b-form-group label="Unidad de medida" label-for="Nombre">

                              <b-form-input
                                  style="display: none"
                                  disabled
                                  v-model="p.uom"
                                  placeholder="Unidad id"
                              />
                              <b-form-input
                                  style="width: 200px"
                                  disabled
                                  v-model="p.uomName"
                                  placeholder="Unidad de medida"
                              />
                            </b-form-group>
                          </td>
                          <td>
                            <v-select
                                style="width: 250px"
                                v-model="p.lots"
                                :clearable="false"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="value"
                                :options="p.lotsData"
                                @input="fetchLotesSet(p.lots,idx)"
                            />

                          </td>
                          <td>
                            <b-form-group label="Cantidad disponible" label-for="Nombre">
                              <b-form-input
                                  disabled
                                  v-model="p.available_qty"
                                  placeholder="Cantidad disponible"
                              />
                            </b-form-group>
                          </td>
                          <td>
                            <b-form-group label="Cantidad Hecha" label-for="Nombre">
                              <b-form-input
                                  @keypress="isNumber($event)"
                                  v-model="p.qty_done"
                                  placeholder="Cantidad Hecha"
                              />
                            </b-form-group>
                          </td>

                          <td>
                            <b-row>
                              <b-col cols="12" v-if="!p.new_line">
                                <b-button
                                    @click="addRow(idx)"
                                    style="width: 100%"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    type="button"
                                    variant="primary"
                                >
                                  Guardar
                                </b-button>
                              </b-col>
                              <b-col cols="12" v-else>
                                <b-button
                                    v-if="p.lineValidate && p.scanQr"
                                    style="width: 100%"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    type="button"
                                    variant="primary"
                                >
                                  Validado
                                </b-button>
                                <b-button
                                    @click="editQtyLine(idx)"
                                    style="width: 100%; margin-top: 10px"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    type="button"
                                    variant="primary"
                                >
                                  Guardar lote
                                </b-button>
                                <b-button
                                    @click="deleteLineDataMoveLine(p.id)"
                                    style="width: 100%; margin-top: 20px"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    type="button"
                                    variant="primary"
                                >
                                  Eliminar Linea
                                </b-button>
                              </b-col>
                            </b-row>
                          </td>
                        </tr>
                      </table>
                    </b-col>
                  </b-tab>
                  <!--                  <b-tab title="Operaciones">-->
                  <!--                    <b-col cols="12" class="mt-3">-->
                  <!--                      <table>-->
                  <!--                        <tr>-->
                  <!--                          <th>Producto</th>-->
                  <!--                          <th>Unidad de medida</th>-->
                  <!--                          <th>Cantidad reservada</th>-->
                  <!--                          <th>Hecho</th>-->
                  <!--                          <th>Acciones</th>-->
                  <!--                        </tr>-->
                  <!--                        <tr v-for="(p, idx) in new_lines" :key="idx">-->
                  <!--                          <td>-->
                  <!--                            <v-select-->
                  <!--                                style="width: 100%"-->
                  <!--                                disabled-->
                  <!--                                v-model="p.name"-->
                  <!--                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
                  <!--                                label="value"-->
                  <!--                                :options="product"-->
                  <!--                            />-->
                  <!--                          </td>-->
                  <!--                          <td>-->
                  <!--                            <b-form-group label="Unidad de medida" label-for="Nombre">-->
                  <!--                              <b-form-input-->
                  <!--                                  style="display: none"-->
                  <!--                                  disabled-->
                  <!--                                  v-model="p.product_id"-->
                  <!--                                  placeholder="Producto id"-->
                  <!--                              />-->
                  <!--                              <b-form-input-->
                  <!--                                  style="display: none"-->
                  <!--                                  disabled-->
                  <!--                                  v-model="p.uom"-->
                  <!--                                  placeholder="Unidad id"-->
                  <!--                              />-->
                  <!--                              <b-form-input-->
                  <!--                                  style="width: 200px"-->
                  <!--                                  disabled-->
                  <!--                                  v-model="p.uomName"-->
                  <!--                                  placeholder="Unidad de medida"-->
                  <!--                              />-->
                  <!--                            </b-form-group>-->
                  <!--                          </td>-->

                  <!--                          <td>-->
                  <!--                            <b-form-group label="Cantidad Reservada" label-for="Nombre">-->
                  <!--                              <b-form-input-->
                  <!--                                  :disabled="true"-->
                  <!--                                  @keypress="isNumber($event)"-->
                  <!--                                  v-model="p.product_uom_qty"-->
                  <!--                                  placeholder="Cantidad Reservada"-->
                  <!--                              />-->
                  <!--                            </b-form-group>-->
                  <!--                          </td>-->
                  <!--                          <td>-->
                  <!--                            <b-form-group label="Cantidad Hecha" label-for="Nombre">-->
                  <!--                              <b-form-input-->
                  <!--                                  :disabled="true"-->
                  <!--                                  @keypress="isNumber($event)"-->
                  <!--                                  v-model="p.qty_done"-->
                  <!--                                  placeholder="Cantidad Hecha"-->
                  <!--                              />-->
                  <!--                            </b-form-group>-->
                  <!--                          </td>-->

                  <!--                          <td>-->
                  <!--                            <b-row>-->

                  <!--                              <b-button-->
                  <!--                                  @click="deleteLineData(p.id)"-->
                  <!--                                  style="width: 100%; margin-top: 20px"-->
                  <!--                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
                  <!--                                  type="button"-->
                  <!--                                  variant="pr imary"-->
                  <!--                              >-->
                  <!--                                Eliminar Linea-->
                  <!--                              </b-button>-->
                  <!--                            </b-row>-->
                  <!--                          </td>-->
                  <!--                        </tr>-->
                  <!--                      </table>-->
                  <!--                    </b-col>-->
                  <!--                  </b-tab>-->
                </b-tabs>

              </b-col>


            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  VBTooltip,
  BOverlay,
  BModal,
  VBModal,
  BFormCheckbox,
  BFormCheckboxGroup,
  BTabs, BTab,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {mapActions, mapGetters} from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {VueGoodTable} from "vue-good-table";
import ModalAppointment from "@/components/Appointments/ModalAppointment";
import flatPickr from "vue-flatpickr-component";
import {QrcodeStream} from "vue-qrcode-reader";
import {urlSocket} from "@/sockets/socket";

export default {
  name: "UpdateInventory",
  components: {
    VueGoodTable,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    VBTooltip,
    BModal,
    VBModal,
    ModalAppointment,
    flatPickr,
    BFormCheckbox,
    BFormCheckboxGroup,
    vSelect,
    BForm,
    QrcodeStream,
    BTabs, BTab,
  },
  data() {
    return {
      show: false,
      urlType: urlSocket,
      show2: false,
      resulScan: null,
      selectPartner: {id: "", value: "Seleccione a un Paciente"},
      selectLocation: {id: "", value: "Seleccione una locacion"},
      optionsPartners: [],
      optionsLocations: [],
      optionsPartnersAll: [],
      id: null,
      error: null,
      productError: null,
      stateAll: null,
      scheduled_date: null,
      openScan: null,
      dataLots: [],
      sendDataDetails: {
        id: null,
        lot_name: null,
        laboratorio: null,
        expiration_date: null,
        qty_done: 1,
      },
      sendNewLine: null,
      reference: null,
      dataError: null,
      positionScan: null,
      linesMove: true,
      product: [],
      productAll: [],
      new_lines: [],
      new_MoveLines: [],
      statusDate: false,
      lineModal: null,
      send: {
        patient_id: null,
        location_id: null,
        origin: null,
      },
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
    "b-modal": VBModal,
  },
  async created() {
    this.id = this.$route.params.id;

    await this.findOneInventorySearch(this.id);
    await this.findPartners();

    await this.findLocations();
  },
  methods: {
    ...mapActions("inventory", [
      "findListPartners",
      "findAllLocations",
      "findOneInventory",
      "validateStockOutExpiry",
      "updateAll",
      "createMoveLine",
      "createMoveLines",
      "verifyMoveLineOut",
      "validateStockOut",
      "createMoveLineOut",
      "validateReady",
      "deleteLine",
      "updateMoveLine",
      "verifyExpirationDate",
      "verifyDateData",
      "createStockLabel",
      "verifyStockAllSave",
      "updateMoveLineQty",
      "deleteMoveLine", "findOneInventoryOut", "validateLotName", "verifyExistLots", "findProductStock"
    ]),
    ...mapActions("treatment", ["findProduct"]),
    async openOtherErp() {
      if (this.urlType === 'localhost:4029' || this.urlType === 'qa.desk.onkimia.com.mx') {
        window.open(`https://qa.erp.onkimia.com.mx/web#id=${this.id}&cids=1&menu_id=254&action=440&active_id=2&model=stock.picking&view_type=form`)
      } else {
        window.open(`https://erp.onkimia.com.mx/web#id=${this.id}&cids=1&menu_id=254&action=440&active_id=2&model=stock.picking&view_type=form`)
      }

    },
    async cancelModal() {

      await this.$bvModal.hide("modal-prevent-move-line");
    },
    async createLinesMove() {
      await this.$bvModal.hide("modal-prevent-move-line");
      this.statusDate = true;
      await this.addRow(this.lineModal);

    },

    async cancelUpdate() {
      await this.$router.push('/lista_salidas')
    },
    async deleteLineData(id) {
      if (this.stateAll === "draft") {
        this.show = true;
        const response = await this.deleteLine(id);
        if (response.msg) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Se elimino la linea correctamente",
              icon: "edit",
              variant: "success",
            },
          });
          this.show = false;
          this.sendDataDetails.lot_name = null;
          this.sendDataDetails.expiration_date = null;
          this.sendDataDetails.qty_done = null;
          this.sendDataDetails.laboratorio = null;
          this.sendDataDetails.id = null;
          await this.findOneInventorySearch(this.id);
          await this.findPartners();
          await this.findProducts();
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error,
              icon: "error",
              variant: "warning",
            },
          });
          this.show = false;
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Sólo puede eliminar movimientos borrador.",
            icon: "error",
            variant: "warning",
          },
        });
      }


    },
    async deleteLineDataMoveLine(id) {
      this.show = true;
      const response = await this.deleteMoveLine(id);
      if (response.msg) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Se elimino la linea correctamente",
            icon: "edit",
            variant: "success",
          },
        });
        this.show = false;
        this.sendDataDetails.lot_name = null;
        this.sendDataDetails.expiration_date = null;
        this.sendDataDetails.qty_done = null;
        this.sendDataDetails.laboratorio = null;
        this.sendDataDetails.id = null;
        await this.findOneInventorySearch(this.id);
        await this.findPartners();
        await this.findProducts();
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "error",
            variant: "warning",
          },
        });
        this.show = false;
      }

    },
    async validateDataStock() {
      const dateTodayExtract = this.dateTodayNotHours();
      this.show = true;

      // for (const a of this.new_MoveLines) {
      //   if (a.id) {
      //     const sendDataVerify = {
      //       product_id:a.product_id,
      //       lot_name: a.lotName
      //     }
      //     const responseDate = await this.verifyDateData(sendDataVerify);
      //     if (responseDate.error) {
      //       this.productError = a.name
      //       await this.$bvModal.show("modal-prevent-move-line-erp");
      //       this.show = false;
      //       return
      //     }
      //
      //   }
      // }
      const response = await this.validateStockOut(this.id);

      if (response.msg) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Se valido correctamente",
            icon: "edit",
            variant: "success",
          },
        });
        this.show = false;
        this.sendDataDetails.lot_name = null;
        this.sendDataDetails.expiration_date = null;
        this.sendDataDetails.qty_done = null;
        this.sendDataDetails.laboratorio = null;
        this.sendDataDetails.id = null;
        await this.findOneInventorySearch(this.id);
        await this.findPartners();
        await this.findProducts();
        await this.$router.push('/lista_salidas')
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "error",
            variant: "warning",
          },
        });
        this.show = false;
      }
    },
    async validateSuccess() {
      this.show = true;
      this.$swal({
        title: 'Proceso',
        text: `Deseas procesar esta orden ya no se podrán añadir líneas`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si continuar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const response = await this.validateReady(this.id);
          if (response.msg) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Se valido correctamente",
                icon: "edit",
                variant: "success",
              },
            });
            this.show = false;
            this.sendDataDetails.lot_name = null;
            this.sendDataDetails.expiration_date = null;
            this.sendDataDetails.qty_done = null;
            this.sendDataDetails.laboratorio = null;
            this.sendDataDetails.id = null;
            await this.findOneInventorySearch(this.id);
            await this.findPartners();
            await this.findProducts();
            this.show = false
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.error,
                icon: "error",
                variant: "warning",
              },
            });
            this.show = false
          }


        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Se cancelo el proceso',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.show = false
        }
      })
    },


    async editQtyLine(id) {

      if (!this.new_MoveLines[id].id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'No se puede editar la linea por que no se ha validado',
            icon: "error",
            variant: "warning",
          },
        });
        return
      }
      if (!this.new_MoveLines[id].qty_done || this.new_MoveLines[id].qty_done === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'La cantidad tiene que ser un valor valido',
            icon: "error",
            variant: "warning",
          },
        });
        return
      }
      if (parseInt(this.new_MoveLines[id].qty_done) > this.new_MoveLines[id].available_qty) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'La cantidad no debe superar la cantidad disponible',
            icon: "error",
            variant: "warning",
          },
        });
        return
      }
      this.show = true;
      const sendDataMedical = {
        id: parseInt(this.new_MoveLines[id].id),
        qty_done: parseInt(this.new_MoveLines[id].qty_done),
        move_id: this.new_MoveLines[id].stockMoveId,
        picking_id: parseInt(this.id),
        product_id: this.new_MoveLines[id].product_id,
        lots: this.new_MoveLines[id].lotName
      }
      const response = await this.updateMoveLineQty(sendDataMedical)
      if (response.msg) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se edito correctamente la linea',
            icon: "edit",
            variant: "success",
          },
        });
        await this.findOneInventorySearch(this.id);
        await this.findPartners();
        this.show = false
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'error al editar contacte con el administrador',
            icon: "error",
            variant: "warning",
          },
        });
        this.show = false
      }
    },

    async addRow(id) {
      const sendLine = this.new_MoveLines[id];
      sendLine.picking_id = parseInt(this.id);
      sendLine.location_id = this.send.location_id;
      sendLine.location_dest_id = 5;
      this.lineModal = null;
      this.dataError = null;
      if (!sendLine.product_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Producto en la linea requerido",
            icon: "error",
            variant: "warning",
          },
        });
        return;
      }
      if (!sendLine.uom) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Unidad de medida en la linea requerido",
            icon: "error",
            variant: "warning",
          },
        });
        return;
      }
      if (!sendLine.picking_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Id en la linea requerido",
            icon: "error",
            variant: "warning",
          },
        });
        return;
      }

      if (this.new_MoveLines[id].qty_done > this.new_MoveLines[id].available_qty) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "La cantidad es mayor a la disponibilidad",
            icon: "error",
            variant: "warning",
          },
        });
        return;
      }
      this.show = true;
        const sendDataVerify = {
          product_id: this.new_MoveLines[id].product_id,
          lot_name: this.new_MoveLines[id].lotName
        }
        const responseDate = await this.verifyDateData(sendDataVerify);

        if (responseDate.error) {

          this.$swal({
            title: 'Proceso',
            text: `Esta producto ${this.new_MoveLines[id].name} ya tiene vencida la fecha quiere continuar`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si continuar',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(async result => {
            if (result.value) {
              const response = await this.createMoveLineOut(sendLine);

              if (response.msg) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Se creo la linea correctamente",
                    icon: "edit",
                    variant: "success",
                  },
                });
                this.show = false;
                this.lineModal = null;
                this.dataError = null;
                this.statusDate = false;
                await this.findProducts();
                await this.findOneInventorySearch(this.id);
                await this.findPartners();

              } else {
                this.show = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.error,
                    icon: "error",
                    variant: "warning",
                  },
                });
              }

            } else if (result.dismiss === 'cancel') {
              this.$swal({
                title: 'Cancelado',
                text: 'Se cancelo el proceso',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.show = false
            }
          })
          return;
        }

      const response = await this.createMoveLineOut(sendLine);

      if (response.msg) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Se creo la linea correctamente",
            icon: "edit",
            variant: "success",
          },
        });
        this.show = false;
        this.lineModal = null;
        this.dataError = null;
        this.statusDate = false;
        await this.findProducts();
        await this.findOneInventorySearch(this.id);
        await this.findPartners();

      } else {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: "error",
            variant: "warning",
          },
        });
      }
    },
    async findOneInventorySearch(firstCharge = true) {
      this.new_lines = [];
      if (!firstCharge) {
        const searchId = this.optionsPartnersAll.find(
            (a) => a.id === this.send.patient_id
        );
        this.selectPartner.id = this.send.product_id;
        this.selectPartner.value = searchId.name;
      }
      const response = await this.findOneInventoryOut(this.id);
      if (response.state === 'done') {
        await this.$router.push('/lista_salidas');
        return
      }
      if (response.state === 'confirmed') {
        await this.$bvModal.show("modal-prevent-move-line-not-product");

      }
      this.scheduled_date = this.resHoursOdoo(response.scheduled_date)
      this.send = {
        patient_id: response.patient_id,
        origin: response.origin,
        location_id: response.location_id,
      };
      await this.findProducts();
      this.stateAll = response.state;
      this.reference = response.name;
      this.new_lines = []
      this.new_MoveLines = []

      if (response.StockMoves.length > 0) {

        this.linesMove = false;
        for (const a of response.StockMoves) {
          let qtyDone = null;
          if (response.StockMoveLineOuts.length > 0) {

            const responseVerifyData = response.StockMoveLineOuts.filter((c) => c.move_id === a.id);

            if (responseVerifyData.length > 0) {

              qtyDone = responseVerifyData.map(obj => parseInt(obj.qty_done)).reduce((account, value) => account + value, 0);

            }
          }
          this.new_lines.push({
            id: a.id,
            product_id: a.product_id,
            name: a.name + " " + `(${a.ProductTemplate.default_code})`,
            uom: a.product_uom,
            uomName: a.UomUom.name,
            product_uom_qty: parseInt(a.product_uom_qty) >= 1 && a.product_uom_qty ? parseInt(a.product_uom_qty) : null,
            product_uom_qty_verify: parseInt(a.product_uom_qty) >= 1 && a.product_uom_qty ? true : false,
            consecutive: a.MedicationMoveLine ? a.MedicationMoveLine.consecutive : null,
            lots: a.MedicationMoveLine ? a.MedicationMoveLine.lots : null,
            date_expiry: a.MedicationMoveLine ? a.MedicationMoveLine.date_expiry : null,
            barcode: a.MedicationMoveLine ? a.MedicationMoveLine.barcode : null,
            qty_done: qtyDone,
            new_line: true,
          });

        }
      }
      if (response.StockMoveLineOuts.length > 0) {
        for (const a of response.StockMoveLineOuts) {
          const verifyExistMove = response.StockMoves.find((b) => b.id === a.move_id)
          let verifyData = false;
          if (a) {
            if (parseInt(a.qty_done) === 0 || !a.qty_done) {
              verifyData = false;
            } else {
              verifyData = true;
            }
          }
          const validateStock = this.productAll.find((b) => b.lot_id === a.StockProductionLot.id)

          this.new_MoveLines.push({
            id: a.id,
            product_id: a.product_id,
            productData: {id: a.product_id, value: verifyExistMove.ProductTemplate.name},
            scanQr: !!verifyExistMove.MedicationMoveLine,
            name: verifyExistMove.name + " " + `(${verifyExistMove.ProductTemplate.default_code})`,
            uom: verifyExistMove.product_uom,
            stockMoveId: verifyExistMove.id,
            available_qty: validateStock.available_quantity,
            uomName: verifyExistMove.UomUom.name,
            product_uom_qty: parseInt(a.product_uom_qty) >= 1 && a.product_uom_qty ? parseInt(a.product_uom_qty) : null,
            product_uom_qty_verify: parseInt(a.product_uom_qty) >= 1 && a.product_uom_qty ? true : false,
            lineValidate: verifyData,
            consecutive: verifyExistMove.MedicationMoveLine ? verifyExistMove.MedicationMoveLine.consecutive : null,
            lots: a.StockProductionLot ? a.StockProductionLot.name : null,
            lotName: a.StockProductionLot ? a.StockProductionLot.name : null,
            lotId: a.StockProductionLot ? a.StockProductionLot.id : null,
            date_expiry: verifyExistMove.MedicationMoveLine ? verifyExistMove.MedicationMoveLine.date_expiry : null,
            barcode: verifyExistMove.MedicationMoveLine ? verifyExistMove.MedicationMoveLine.barcode : null,
            qty_done: parseInt(a.qty_done),
            qty_done_edit: 1,
            new_line: true,
          });

        }
      }
      this.new_lines.push({
        id: null,
        product_id: "",
        stock_move_line_id: null,
        stockMoveId: null,
        scanQr: false,
        product_uom_qty: null,
        name: "",
        uom: "",
        lineValidate: false,
        uomName: "",
        consecutive: "",
        lots: "",
        qty_done: null,
        qty_done_edit: null,
        date_expiry: "",
        barcode: "",
        new_line: false,
      });
      this.new_MoveLines.push({
        id: null,
        product_id: null,
        productData: null,
        stock_move_line_id: null,
        stockMoveId: null,
        scanQr: false,
        product_uom_qty: null,
        name: "",
        available_qty: "",
        uom: "",
        lineValidate: false,
        uomName: "",
        consecutive: "",
        lots: null,
        lotName: null,
        lotId: null,
        lotsData: [],
        qty_done: 1,
        qty_done_edit: null,
        date_expiry: "",
        barcode: "",
        new_line: false,
      });

    },
    async fetchLotes(productId, idx) {
      const lots = this.productAll.filter(p => p.product_id === productId.id);
      const dataComplete = this.productAll.find(p => p.product_id === productId.id);


      if (this.new_MoveLines[idx]) {
        this.new_MoveLines[idx].lots = null;
        this.new_MoveLines[idx].available_qty = null;
        this.new_MoveLines[idx].qty_done = null;
        this.new_MoveLines[idx].uomName = dataComplete.name;
        this.new_MoveLines[idx].uom = dataComplete.uomid;
        this.new_MoveLines[idx].product_id = productId.id;
        let dataNew = []
        this.new_MoveLines[idx].lotsData = []
        for (const a of lots) {
          dataNew.push({
            id: a.lot_id,
            value: a.lot_name,
          })
        }
        this.new_MoveLines[idx].lotsData = dataNew;
      }
    },

    async fetchLotesSet(lotesId, idx) {
      const dataComplete = this.productAll.find(p => p.lot_id === lotesId.id);

      if (this.new_MoveLines[idx]) {
        this.new_MoveLines[idx].lotId = dataComplete.lot_id;
        this.new_MoveLines[idx].lotName = dataComplete.lot_name;
        this.new_MoveLines[idx].available_qty = dataComplete.available_quantity;
        this.new_MoveLines[idx].reserved_quantity = dataComplete.reserved_quantity;
      }
    },
    async findProducts() {
      this.productAll = [];
      this.product = [];
      const response = await this.findProductStock(this.send.location_id);

      this.productAll = response.products;

      const uniqueProducts = {};
      for (const a of response.products) {
        if (!uniqueProducts[a.product_id]) {
          uniqueProducts[a.product_id] = {
            id: a.product_id,
            value: a.product_name
          };
        }
      }

      this.product = Object.values(uniqueProducts);
    },
    async verifyData() {
      if (!this.send.patient_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Campo Paciente es requerido",
            icon: "error",
            variant: "warning",
          },
        });
        return false;
      }
      return true;
    },
    async sendInventory(data) {
      const verifyDataResponse = await this.verifyData();
      if (!verifyDataResponse) return;
      const dataSend = {
        patient_id: this.send.patient_id,
        origin: this.send.origin,
      };

      const response = await this.updateAll({id: this.id, data: dataSend});
      if (response.msg) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Se edito correctamente",
            icon: "EditIcon",
            variant: "success",
          },
        });
        await this.findOneInventorySearch();
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error al editar contacte con el administrador",
            icon: "error",
            variant: "warning",
          },
        });
      }
    },

    async clearDate() {
      this.send.scheduled_date = null;
    },
    async searchByPartner() {
      this.send.partner_id = this.selectPartner ? this.selectPartner.id : null;
    },
    async resetModal() {
    },
    async resetModalQr() {
    },
    async findPartners() {
      this.optionsPartners = [];
      this.optionsPartnersAll = [];
      const response = await this.findListPartners();

      for (const a of response.partners) {
        if (a.id === this.send.patient_id) {
          this.selectPartner.id = this.send.patient_id;
          this.selectPartner.value = a.name;
        }
        this.optionsPartners.push({
          id: a.id,
          value: a.name,
        });
        this.optionsPartnersAll.push({
          id: a.id,
          name: a.name,
        });
      }
    },
    async findLocations() {
      const response = await this.findAllLocations();
      for (const a of response.locations) {
        this.optionsLocations.push({
          id: a.id,
          value: a.complete_name
        })
      }
      const responseData = response.locations.find(data => data.id === this.send.location_id);
      this.selectLocation.id = responseData.id;
      this.selectLocation.value = responseData.complete_name;
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: necesita otorgar permiso de acceso a la cámara";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no hay cámara en este dispositivo";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: Se requiere contexto seguro (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: ¿La cámara ya está en uso?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: las cámaras instaladas no son adecuadas";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API no es compatible con este navegador";
        } else if (error.name === "InsecureContextError") {
          this.error =
              "ERROR: El acceso a la cámara solo está permitido en un contexto seguro. Utilice HTTPS o localhost en lugar de HTTP.";
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },
  },
};
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
}

.error {
  font-weight: bold;
  font-size: 20px;
  color: red;
}
.v-select .dropdown-toggle .clear {
  display: none;
}
</style>
